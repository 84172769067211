<template>
	<div class="editor suggestion-container" @click="editor.focus()" :class="options.taller ? 'taller' : ''">

		<editor-menu-bar :editor="editor" v-slot="{ commands, isActive }" style="line-height:1.618">

			<div class="menubar" v-if="format">

				<button class="editor-menubar__button" @click="commands.bold">
					<a-icon type="bold" :class="isActive.bold() ? '' : 'text-med-gray'"></a-icon>
				</button>
				<button class="editor-menubar__button" @click="commands.italic">
					<a-icon type="italic" :class="isActive.italic() ? '' : 'text-med-gray'"></a-icon>
				</button>
				<button class="editor-menubar__button" @click="commands.underline">
					<a-icon type="underline" :class="isActive.underline() ? '' : 'text-med-gray'"></a-icon>
				</button>
				<a-popover :visible="showLinkOptionsBoolean" title="Apply Link" trigger="click">
					<div class="button-editor-container" slot="content">
						<template v-if="builder !== 'email'">
							<p style="line-height:1" class="my-2">Link Type</p>
							<a-select class="mb-2" v-model="form.type" :options="linkType" />
						</template>

						<template v-if="form.type === 'link'">
							<p style="line-height:1" class="my-2">External Link (URL)</p>
							<a-input v-model="form.value" />
						</template>

						<template v-else-if="form.type === 'section'">
							<p style="line-height:1" class="my-2">Select a Section/Modal</p>
							<a-select v-model="form.value" :options="pageSectionsOptions" />
						</template>
						<template v-else-if="form.type === 'page'">
							<p style="line-height:1" class="my-2">Select a Page</p>
							<a-select v-model="form.value" :options="pagesOptions" />
						</template>
						<div class="mt-4 dF" style="gap:1ch;">
							<a-button type="primary" @click="applyLink(commands, isActive)">Apply</a-button>
							<a-button>Cancel</a-button>
						</div>
					</div>
					<button class="editor-menubar__button" @click="() => showLinkOptions(commands, isActive)">
						<a-icon type="link" :class="isActive.link() ? 'text-purple' : 'text-med-gray'"></a-icon>
					</button>
				</a-popover>

				<button class="editor-menubar__button" @click="commands.bullet_list" v-if="options.bullets">
					<a-icon type="unordered-list" :class="isActive.bullet_list() ? '' : 'text-med-gray'"></a-icon>
				</button>
				<button class="editor-menubar__button"
					@click="commands.heading({ level: isNaN(parseInt(options.sizeToggle)) ? 2 : parseInt(options.sizeToggle) })"
					v-if="Boolean(options.sizeToggle)">
					<a-icon type="font-size" :class="isActive.heading({ level: 2 }) ? '' : 'text-med-gray'"></a-icon>
				</button>


				<template v-if="false">

					<button class="menubar__button" :class="{ 'is-active': isActive.italic() }"
						@click="commands.italic">
						<a-icon name="italic" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.strike() }"
						@click="commands.strike">
						<a-icon type="strike" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.underline() }"
						@click="commands.underline">
						<a-icon type="underline" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code">
						<icon name="code" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }"
						@click="commands.paragraph">
						<a-icon type="paragraph" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }"
						@click="commands.heading({ level: 1 })">
						H1
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }"
						@click="commands.heading({ level: 2 })">
						H2
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }"
						@click="commands.heading({ level: 3 })">
						H3
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }"
						@click="commands.bullet_list">
						<icon name="ul" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }"
						@click="commands.ordered_list">
						<icon name="ol" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }"
						@click="commands.blockquote">
						<icon name="quote" />
					</button>

					<button class="menubar__button" :class="{ 'is-active': isActive.code_block() }"
						@click="commands.code_block">
						<icon name="code" />
					</button>

					<button class="menubar__button" @click="commands.horizontal_rule">
						<icon name="hr" />
					</button>

					<button class="menubar__button" @click="commands.undo">
						<icon name="undo" />
					</button>

					<button class="menubar__button" @click="commands.redo">
						<icon name="redo" />
					</button>
				</template>
			</div>
		</editor-menu-bar>

		<div class="suggestion-list" v-show="showSuggestions && !forceClose && variable" ref="suggestions">
			<template v-if="hasResults">
				<div v-for="(user, index) in filteredUsers" :key="user.id" class="suggestion-list__item"
					:class="{ 'is-selected': navigatedUserIndex === index }" @click="selectUser(user)">
					{{ user.name }}
				</div>
			</template>
			<div v-else class="suggestion-list__item is-empty">
				No Variable Found
			</div>
		</div>

		<editor-content class="editor__content" :editor="editor" style="overflow-y: auto;"
			:style="`${options.taller ? `height:350px;` : ``}max-height:${options.taller ? 400 : 200}px`" />
		<p v-if="variable" class="mb-0 px-2 variable-tip"
			style="line-height:1.5; background:var(--light-purple); font-size:.8em; color:#898f98; padding:3px;">To use
			variables in the content, use the <span class="text-black">'@'</span> sign.</p>
	</div>
</template>



<script>
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
// import { Editor, EditorContent, EditorMenuBar,EditorMenuBubble } from '@tiptap/vue-2'
// import StarterKit from '@tiptap/starter-kit'
// import Document from '@tiptap/extension-document'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
import {
	Blockquote,
	CodeBlock,
	HardBreak,
	Heading,
	HorizontalRule,
	OrderedList,
	BulletList,
	ListItem,
	TodoItem,
	TodoList,
	Bold,
	Code,
	Italic,
	Strike,
	Underline,
	Link,
	History,
	Mention,
} from 'tiptap-extensions'
import tippy, { sticky } from 'tippy.js'

export default {
	props: {
		value: {
			type: String,
			default: ''
		},
		format: {
			type: Boolean,
			default: true
		},
		variable: {
			type: Boolean,
			default: true,
		},
		options: {
			type: Object,
			default: () => ({})
		},
		builder: {
			type: String,
			default: 'web'
		}
	},
	components: {
		EditorContent,
		EditorMenuBar,
		EditorMenuBubble,
	},
	data: () => ({
		form: {
			type: "section",
			value: '',
		},
		linkUrl: null,
		showLinkOptionsBoolean: false,
		editor: null,
		query: null,
		suggestionRange: null,
		filteredUsers: [],
		navigatedUserIndex: 0,
		insertMention: () => { },
		forceClose: false,
		suggestionList: [
			{ id: 'recepient_name', name: 'Full Name', value: 'Name@' },
			{ id: 'recepient.firstName', name: 'First Name', value: 'First_Name@' },
			{ id: 'recepient.lastName', name: 'Last Name', value: 'Last_Name@' },
			{ id: 'recepient_email', name: 'Email', value: 'Email@' },
		]
	}),
	watch: {
		'form.type'(val) {
			if (val === 'section') {
				this.form.value = this.pageSectionsOptions[0].value
			} else if (val === 'page') {
				this.form.value = this.pagesOptions[0].value
			} else {
				this.form.value = ''
			}
		}
	},
	computed: {
		linkType() {
			let types = [{ label: 'External Link', value: 'link' }, { label: 'Goto Section', value: 'section' }]
			if (!this.$store.getters.isLanding) types.push({ label: 'Goto Page', value: 'page' })
			return types
		},
		pageSectionsOptions() {
			return this.$store.getters.pageSectionsOptions
		},
		pagesOptions() {
			return this.$store.getters.pagesOptions
		},
		hasResults() {
			return this.filteredUsers.length
		},
		showSuggestions() {
			return this.query || this.hasResults
		},
	},
	methods: {
		showLinkOptions(commands, isActive) {
			if (this.builder === 'web') {
				this.form = {
					type: "section",
					value: this.pageSectionsOptions[0].value,
				}
			} else {
				this.form = {
					type: "link",
					value: '',
				}
			}

			if (isActive.link()) {
				this.showLinkOptionsBoolean = false
				return commands.link({ href: null })
			}

			return this.showLinkOptionsBoolean = true
		},
		// navigate to the previous item
		// if it's the first item, navigate to the last one
		upHandler() {
			this.navigatedUserIndex = ((this.navigatedUserIndex + this.filteredUsers.length) - 1) % this.filteredUsers.length
		},
		// navigate to the next item
		// if it's the last item, navigate to the first one
		downHandler() {
			this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length
		},
		enterHandler() {
			const user = this.filteredUsers[this.navigatedUserIndex]
			if (user) {
				this.selectUser(user)
			}
		},
		// we have to replace our suggestion text with a mention
		// so it's important to pass also the position of your suggestion text
		selectUser(user) {
			this.insertMention({
				range: this.suggestionRange,
				attrs: {
					id: user.id,
					label: user.value,
				},
			})
			this.editor.focus()
		},
		renderPopup(node) {
			if (this.popup && this.popup.length && !this.variable) {
				return console.error('POPUP ALREADY EXISTS')
			}

			// ref: https://atomiks.github.io/tippyjs/v6/all-props/

			this.popup = tippy(this.$el, {
				getReferenceClientRect: node.getBoundingClientRect,
				appendTo: () => document.body,
				interactive: true,
				sticky: true, // make sure position of tippy is updated when content changes
				plugins: [sticky],
				content: this.$refs.suggestions,
				trigger: 'mouseenter', // manual
				showOnCreate: true,
				theme: 'dark',
				placement: 'top-start',
				inertia: true,
				duration: [400, 200],
			})
		},
		destroyPopup() {
			if (this.popup && this.popup[0]) {
				this.popup[0].destroy()
				this.popup = null
			}
		},
		setLink(commands, active) {
			if (active) return commands.link({ href: null })
			commands.link({ href: 'https://www.google.com', name: 'goooooogle' })
			this.showLinkOptionsBoolean = false
		},
		applyLink(commands, isActive) {
			let value = this.form.value

			if (this.form.type === 'section') {
				value = `#/sect-${value}`
			}

			commands.link({ href: value })
			this.showLinkOptionsBoolean = false
			if (this.builder === 'web') {
				this.form = {
					type: "section",
					value: this.pageSectionsOptions[0].value,
				}
			} else {
				this.form = {
					type: "link",
					value: '',
				}
			}
		},


	},
	beforeDestroy() {
		this.destroyPopup()
	},
	created() {
		if (this.builder === 'email') {
			this.form.type = 'link'
		}
	},
	mounted() {

		window.tippy = tippy
		// this.editor = new Editor({
		//     content: '<p>I’m running tiptap with Vue.js. 🎉</p>',
		//     extensions: [
		//         StarterKit,
		//     ],
		// })
		return this.editor = new Editor({
			onInit: () => {
				// editor is initialized
			},
			onUpdate: (obj) => {
				let { getHTML } = obj
				let newContent = getHTML()
				if (!this.format) {
					newContent = newContent.replace(/<p>/g, '')
					newContent = newContent.replace(/<\/p>/g, '')
					newContent = newContent.replace(/<div>/g, '')
					newContent = newContent.replace(/<\/div>/g, '')
					newContent = newContent.replace(/<ul>/g, '')
					newContent = newContent.replace(/<\/ul>/g, '')
				}
				this.$emit('input', newContent)

			},
			onFocus: () => {
				this.$el.classList.add('focused')
			},
			onBlur: () => {
				this.$el.classList.remove('focused')
			},
			extensions: [
				new Blockquote(),
				new BulletList(),
				new CodeBlock(),
				new HardBreak(),
				new Heading({ levels: [1, 2, 3] }),
				new HorizontalRule(),
				new ListItem(),
				new OrderedList(),
				new TodoItem(),
				new TodoList(),
				new Link(),
				new Bold(),
				new Code(),
				new Italic(),
				new Strike(),
				new Underline(),
				new History(),
				new Mention({
					// a list of all suggested items
					items: async () => {
						await new Promise(resolve => {
							setTimeout(resolve, 500)
						})
						return this.suggestionList
					},
					// is called when a suggestion starts
					onEnter: ({ items, query, range, command, virtualNode, }) => {
						this.query = query
						this.filteredUsers = items
						this.suggestionRange = range
						this.renderPopup(virtualNode)
						// we save the command for inserting a selected mention
						// this allows us to call it inside of our custom popup
						// via keyboard navigation and on click
						this.insertMention = command
					},
					// is called when a suggestion has changed
					onChange: ({ items, query, range, virtualNode, }) => {
						this.query = query
						this.filteredUsers = items
						this.suggestionRange = range
						this.navigatedUserIndex = 0
						this.renderPopup(virtualNode)
					},
					// is called when a suggestion is cancelled
					onExit: () => {
						// reset all saved values
						this.query = null
						this.filteredUsers = []
						this.suggestionRange = null
						this.navigatedUserIndex = 0
						this.destroyPopup()
					},
					// is called on every keyDown event while a suggestion is active
					onKeyDown: ({ event }) => {
						if (event.key === 'ArrowUp') {
							this.upHandler()
							return true
						}
						if (event.key === 'ArrowDown') {
							this.downHandler()
							return true
						}
						if (event.key === 'Enter') {
							this.enterHandler()
							return true
						}
						return false
					},
					// is called when a suggestion has changed
					// this function is optional because there is basic filtering built-in
					// you can overwrite it if you prefer your own filtering
					// in this example we use fuse.js with support for fuzzy search
					onFilter: async (items, query) => {
						return items

						if (!query) {
							return items
						}
						await new Promise(resolve => {
							setTimeout(resolve, 500)
						})
						const fuse = new Fuse(items, {
							threshold: 0.2,
							keys: ['name'],
						})
						return fuse.search(query).map(item => item.item)
					},
				}),
			],
			content: !this.format ? `<p>${this.value}</p>` : this.value,
		})
	},
	beforeDestroy() {
		this.editor.destroy()
	},
}
</script>




<style lang="scss">
$color-black: #444;
$color-white: #fff;

.editor.taller {}

.mention {
	background: var(--light-purple);
	color: rgba($color-black, 0.6);
	font-size: 0.8rem;
	border-radius: 5px;
	padding: 0.2rem 0.5rem;
	white-space: nowrap;
}

.mention-suggestion {
	color: rgba($color-black, 0.6);
}

.suggestion-list {
	padding: 0.2rem;
	border: 2px solid rgba($color-black, 0.1);
	font-size: 0.8rem;
	font-weight: bold;

	&__no-results {

		padding: 0.2rem 0.5rem;
	}

	&__item {

		border-radius: 5px;
		padding: 0.2rem 0.5rem;
		margin-bottom: 0.2rem;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}

		&.is-selected,
		&:hover {
			background-color: rgba($color-white, 0.2);
		}

		&.is-empty {
			opacity: 0.5;
		}

	}
}

.tippy-box[data-theme~=dark] {
	background-color: $color-black;
	padding: 0;
	font-size: 1rem;
	text-align: inherit;
	color: $color-white;
	border-radius: 5px;
}

.variable-tip {
	opacity: 0;
	transition: opacity .3s ease-out;
}

.suggestion-container {
	&.focused {
		.variable-tip {
			opacity: 1;
		}
	}
}

.editor__content {
	a {
		color: var(--purple);
		pointer-events: none;
	}

	p {
		margin-bottom: 5px;
	}

	ul {
		padding-inline-start: 20px;
	}

	h1,
	h2,
	h3,
	h4 {
		font-size: 1.3em;
	}
}
</style>
